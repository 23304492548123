import React, { useEffect, useState } from "react";
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { baseURL, getShoppingCategories } from "../services/Aut.post.js";

function ShoppingCategory({isAuthenticated}) {
  const [allDeals, setAllDeals] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getShoppingCategories();
        setAllDeals(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching deals:", error);
      }
    };

    fetchData();
  }, []);

  const onClickCategories = (value) => {
    localStorage.setItem("selectedShoppingCategoryName", value.Product_category_name);
    navigate(`/shopping/category/${value.Product_category_name}/${value.id}`);
    // if (isAuthenticated) {
    //   navigate(`/shopping/category/${value.id}`);
    // } else {
    //   // Show the popup message
    //   const popup = document.querySelector(".custom-popup-container");
    //   popup.style.display = "flex";
    // }
  };

 




  return (
    <>
      <div className="top-space-1">
        <div className="extra-offer-wrap">
          <h1>Shopping Categories</h1> {/* Page Title */}
          <div className="extra-offer-flex-2-wrap">
            {allDeals.length > 0 ? (
              allDeals.map((value, index) => (

                <div className="extra-offer-2" key={index}>
                   <div className="extra-offer-2-img" key={index}>
                   <img
                    src={
                      value.Product_category_image
                        ? `${baseURL}/${value.Product_category_image}`
                        : amazon
                    }
                    alt={value.Product_category_image || "product"}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = amazon;
                    }}
                  />
                   </div>
                  <h6>
                 
                    {/* Title (optional, if available) */}
                    
                    
                    {/* Navigate to the inner page of the category using the category ID */}
                    {/* <Link to={`/category/${value.id}`}>
                      View {value.category_name} Deals
                    </Link> */}
                     <button onClick={() => onClickCategories(value)}>
                     {value.Product_category_name} 
                  </button>
                  </h6>
                </div>

              ))
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>

         
    </>
  );
}

export default ShoppingCategory;
