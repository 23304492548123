import React from "react";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const navigate = useNavigate();

  return (


    <div className="top-space-1">
    <div className="claim-page">
      <div className="claim-page-bg payment-status-container success">
      <h1>Payment Successful ✅</h1>
        {/* <p>
          Thank you for your payment! 
        </p> */}
        <p>Thank you for your purchase!</p>
        <button className="button-orange-1 w-80" onClick={() => navigate("/")}>   Back to Home</button>
      </div>
    </div>
  </div>
  );
};

export default Success;
