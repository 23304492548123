import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./Styles.css";
import { jwtDecode } from "jwt-decode";
import Home from "./components/Home";
import PhoneSignUp from "./components/PhoneSignUp";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import Header from "./components/Header/Header";
import SignUp from "./components/SignUp";
import Footer from "./components/Footer/Footer";
import Subscriptions from "./components/Subscriptions";
import ChangeSubscriptions from "./components/ChangeSubscriptions";
import ContactUs from "./components/ContactUs";
import TermsandConditions from "./components/TermsandConditions";
import AboutUs from "./components/AboutUs";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Shopping from "./components/Shopping";
import Deals from "./components/Deals";
import Survey from "./components/Survey";
import ClaimPage from "./components/ClaimPage";
import OrderID from "./components/OrderID";
import SubNew from "./components/SubNew";
import RefundPolicy from "./components/RefundPolicy";
import Checkout from "./components/Checkout";
import Surveylist from "./components/Surveylist";
import SurveyDetails from "./components/SurveyDetails";
import TokenTransition from "./components/TokenTransition";
import MyOrders from "./components/MyOrders";
import DealsCategory from "./components/DealsCategory";
import SurveyConfrim from "./components/SurveyConfrim";
import DealsCategoryInner from "./components/DealsCategoryInner"
import ShoppingProductDetails from "./components/ShoppingProductDetails"
import ShoppingCategory from "./components/shoppingCategory";
import ShoppingCategoryInner from "./components/ShoppingCategoryInner";

// //////

import Login from "./components/Login";
import CommonHeader from "./components/Header/CommonHeader";
import MyAccountStatus from "./components/MyAccountStatus";
// import ChangeAddress from "./components/ChangeAddress";
// import Subscriptions from "./components/Subscriptions";
import Winnings from "./components/Winnings";
import Logout from "./components/Logout";
import ProfilePicModal from "./components/ProfilePicModal";
import Dashboard from "./components/Dashboard";
// import ChangeSubscriptions from "./components/ChangeSubscriptions";
// import ChangeAadharPan from "./components/ChangeAadharPan";
// import ContactUs from "./components/ContactUs";
// import TermsandConditions from "./components/TermsandConditions";
// import AboutUs from "./components/AboutUs";
// import PrivacyPolicy from "./components/PrivacyPolicy";

import Testone from "./components/Testone";
import GameStepTwo from "./components/GameStepTwo";
import LoginRedirectPage from "./components/LoginRedirectPage";
import Identity from "./components/Identity";
// import Shopping from "./components/Shopping";
// import Deals from "./components/Deals";
// import Survey from "./components/Survey";
// import ClaimPage from "./components/ClaimPage";
// import OrderID from "./components/OrderID";
// import SubNew from "./components/SubNew";
// import RefundPolicy from "./components/RefundPolicy";
// import Checkout from "./components/Checkout";
import ConfirmPage from "./components/ConfirmPage";
// import Surveylist from "./components/Surveylist";
// import SurveyDetails from "./components/SurveyDetails";
import MiddleAddPage from "./components/MiddleAddPage";
import DealsError from "./components/DealsError";
import SurveyCategory from "./components/SurveyCategory";
import Surveyadvertisement from "./components/SurveyMiddleAddPage";
import ShoppingConfirmPage from "./components/shoppingConfirmPage"
import PaymentGateWay from "./components/PaymentGateWay";
import SubScriptionFeatures from "./components/subscriptionFeatures"
import PaymentForm from "./components/examplepayment"
// import StringArtGenerator from "./components/string"
import PayUIntegration from "./components/PayUIntegration "
import SuccessfulPayment  from "./components/SuccessfulPayment "
import FailedPayment  from "./components/FailedPayment "
import Success  from "./components/shoppingpaymentsucess"
import Failure from "./components/shoppingpaymentfailure";
import MainSubScriptionFeatures from "./components/MainSubscriptionFeatures"

//test 
import FunctionTest from "./components/test"

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem("user")
  );


  useEffect(() => {
    const handleLogout = () => {
      localStorage.removeItem("user");
      localStorage.removeItem("userLoginStatus");
      setIsAuthenticated(false);
      window.location.href = "/phonesignup"; // Redirect to PhoneSignUp
    };
  
    const checkTokenValidity = () => {
      const userLoginStatus = JSON.parse(localStorage.getItem("user"));
  
      if (userLoginStatus && userLoginStatus.access) {
        try {
          const decoded = jwtDecode(userLoginStatus.access);
          const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  
          if (decoded.exp < currentTime) {
            // Token has expired, show alert
            const userResponse = window.confirm(
              "Your session has expired. Please login again to continue."
            );
  
            if (userResponse) {
              handleLogout(); // Log out and redirect
            } else {
              handleLogout(); // Clean up even if the user cancels
            }
            return false; // Token is invalid
          }
  
          return true; // Token is valid
        } catch (error) {
          console.error("Error decoding token:", error);
          handleLogout(); // Handle errors gracefully
          return false;
        }
      }
  
      return false; // No token available
    };
  
    checkTokenValidity();
  
    // Optionally, set an interval to periodically check token validity
    const interval = setInterval(checkTokenValidity, 1000); // Check every second
    return () => clearInterval(interval);
  }, []);
  

  const handleLogin = (token) => {
    localStorage.setItem("user", token);
    setIsAuthenticated(true);
  };



  const handleLogout = () => {
    // localStorage.removeItem("user");
    // localStorage.removeItem("userphonenumber");
    // localStorage.removeItem("_grecaptcha");
    // localStorage.removeItem("gridcount");
    // localStorage.removeItem("contest_name");
    // localStorage.removeItem("game_demo_link");
    // localStorage.removeItem("sponcer_name");
    localStorage.clear();
    setIsAuthenticated(false);
  };

  return (
    <Router>
      <div>
        <div className="min-height-1">
          <Header
            isAuthenticated={isAuthenticated}
            handleLogout={handleLogout}
          />
          <div className=""> <UserAuthContextProvider>
            <Routes>
              <Route
                path="/home"
                element={isAuthenticated ? <Home /> : <Navigate to="/" />}
              />
              <Route path="/" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route
                path="/phonesignup"
                element={
                  isAuthenticated ? (
                    <Navigate to="/home" />
                  ) : (
                    <PhoneSignUp handleLogin={handleLogin} />
                  )
                }
              />
              
              <Route
                path="/SignUp"
                element={
                  isAuthenticated ? (
                    <Navigate to="/home" />
                  ) : (
                    <SignUp handleLogin={handleLogin} />
                  )
                }
              />
              <Route
                path="/MyAccountStatus"
                element={
                  isAuthenticated ? <MyAccountStatus /> : <Navigate to="/" />
                }
              />
              <Route
                path="/Winnings"
                element={isAuthenticated ? <Winnings /> : <Navigate to="/" />}
              />
              <Route
                path="/ProfilePicModal"
                element={
                  isAuthenticated ? <ProfilePicModal /> : <Navigate to="/" />
                }
              />
              <Route
                path="/Dashboard"
                element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
              />
              {/* <Route path="/ChangeAadharPan"element={  isAuthenticated ? <ChangeAadharPan /> : <Navigate to="/" />  }/> */}
              <Route
                path="/TermsandConditions"
                element={<TermsandConditions />}
              />
              <Route path="/Thankyou" element={<DealsError />} />
              <Route path="/AboutUs" element={<AboutUs />} />
              <Route path="/Shopping" element={<Shopping isAuthenticated={isAuthenticated} />} />
              <Route path="/Shopping-Categories" element={<ShoppingCategory isAuthenticated={isAuthenticated} />} />
              <Route path="/shopping/category/:Product_category_name/:id" element={<ShoppingCategoryInner isAuthenticated={isAuthenticated}  />} />

              <Route path="/DealsCategories" element={<DealsCategory isAuthenticated={isAuthenticated} />} />
              <Route path="/deals/category/:category_name/:id" element={<DealsCategoryInner isAuthenticated={isAuthenticated} />} />
              <Route path="/Deals" element={<Deals />} />

              {/* updating the survey list  */}
              {/* <Route path="/survey-list" element={<Surveylist />} /> */}
              <Route path="/Survey-Categories/Survey-:categoryName-list/:categoryId" element={<Surveylist  />} />
              <Route
                path="/Survey-Categories/Survey-:categoryName-list/:id/:cname"
                // element={<SurveyDetails />}
                element={
                  isAuthenticated ? (
                    <SurveyDetails />
                  ) : (
                    <Navigate to="/phonesignup" />
                  )
                }
              />
              <Route path="/survey" element={<Survey />} />
              <Route path="/Survey-Categories" element={<SurveyCategory isAuthenticated={isAuthenticated} />} />
              <Route path="/Subscriptions" element={<Subscriptions />} />
              <Route 
                path="/claim-page/:unq_id"
                element={
                  isAuthenticated ? (
                    <ClaimPage />
                  ) : (
                    <Navigate to="/phonesignup"/>
                  )
                }
              />
              <Route path="/order-id/:unq_id" element={<OrderID />} />
              <Route path="/confirm-page/:unq_id" element={<ConfirmPage />} />
              <Route path="/shopping-confirm-page/:unq_id" element={<ShoppingConfirmPage />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
               <Route path="/ContactUs" element={<ContactUs />} /> 
              <Route path="/SubNew" element={<SubNew />} />

              <Route path="/Survey-Confrim" element={<SurveyConfrim />} />
              <Route path="/RefundPolicy" element={<RefundPolicy />} />
              <Route
                path="/Checkout/:unq_id/"
                element={
                  isAuthenticated ? (
                    <Checkout />
                  ) : (
                    <Navigate to="/phonesignup" />
                  )
                }
              />
              <Route
                path="/ChangeSubscriptions"
                element={<ChangeSubscriptions />}
              />
              <Route
                path="/getsubscriptionvalues/:subscription_level/:subscription_type"
                element={<ChangeSubscriptions />}
              />
              <Route path="/Testone" element={<Testone />} />
              <Route path="/GameStepOne" />
              <Route
                path="/GameStepTwo"
                element={
                  isAuthenticated ? <GameStepTwo /> : <Navigate to="/" />
                }
              />

              <Route
                path="/LoginRedirectPage"
                element={<LoginRedirectPage />}
              />

              <Route path="/identity" element={<Identity />} />
              <Route path="/My-Orders" element={<MyOrders />} />
              <Route path="/token-transition" element={<TokenTransition />} />
              <Route path = "/PaymentGateWay/:unq_id" element={<PaymentGateWay/>}/>

              {/* <Route exact path="/advertisement" element={<MiddleAddPage />} /> */}

              <Route exact path="/advertisement" element={
                 <MiddleAddPage />
                } />

<Route exact path="/survey-advertisement" element={
                  <Surveyadvertisement />
                } />
                <Route  path = "/MainSubScriptionFeatures"
              element={<MainSubScriptionFeatures/>}
              />

              {/* <Route
                path="/Gamep"
                element={
                  <>
                    <CommonHeader />
                    {isAuthenticated ? <Gamep /> : <Navigate to="/" />}
                  </>
                }
              /> */}
              {/* <Route
                path="/SortingStarGame"
                element={
                  <>
                    <CommonHeader />
                    {<BoxPizzaGame />}
                  </>
                }
              /> */}
              {/* <Route
                path="/ChangeAddress"
                element={
                  isAuthenticated ? <ChangeAddress /> : <Navigate to="/" />
                }
              /> */}
              {/* <Route exact path = "/OrderID" element={OrderID} /> */}

              <Route eact path="/Product-details/:product_unqcode" element={<ShoppingProductDetails />} />
              <Route exact path = "/SubScriptionFeatures" element={<SubScriptionFeatures/>}/>
              <Route path = "/PaymentForm" element={<PaymentForm/>}/>
              <Route ptah = "/PayUIntegration" element = {<PayUIntegration/>}/>
              <Route path="/payment-success" element={<SuccessfulPayment />} />
              <Route path="/payment-failed" element={<FailedPayment />} />
              <Route path="/success" element={<Success />} />
              <Route path="/failure" element={<Failure />} />
            </Routes>
          </UserAuthContextProvider></div>
         
          {/* <PayUIntegration/> */}
          
        </div>


       

        <Footer />
      </div>
    </Router>
  );
}

export default App;
