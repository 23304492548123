// api.js
import axios from "axios";





// const baseURL = "http://127.0.0.1:8000"; // Replace with your actual API base URL
// const ssoURL = "http://localhost:3001";
// const ssoRedirectUrl = "http://localhost:3000"; 

const baseURL = 'https://admin.bigbonanza.in';
const ssoURL = 'https://tockens.com';
const ssoRedirectUrl = 'https://bigbonanza.in';

const api = axios.create({
  baseURL: "http://127.0.0.1:8000", // Update to your Django backend URL
  headers: {
    "Content-Type": "application/json",
  },
});

//tockens

const getProductsAll = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(`${baseURL}/tockens_api/getallproducts/`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

const getDealsAll = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(
      `${baseURL}/tockens_api/get-marketplace-deals-home/`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

const getDealsCategories = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(
      `${baseURL}/tockens_api/getall-marketplace-category/`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};



const getDealsCategoriesInner = async (id ) => {
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(
      `${baseURL}/tockens_api/getall-marketplace-deals/${id}`,
      {
        method: "GET",
        headers: headers, 
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};


const getDeal = async (getDealUnqCode,accessToken) => {
  const headers = {
    "Content-Type": "application/json",
    'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(
      `${baseURL}/tockens_api/get-marketplace-deal/${getDealUnqCode}/`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

const getProduct = async (getDealUnqCode) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(
      `${baseURL}/tockens_api/getproduct/${getDealUnqCode}/`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

const getAllResult = async (firstArrg) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(`${baseURL}/tockens_api/${firstArrg}/`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

const getAllResultNoTocken = async (firstArrg) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(`${baseURL}/tockens_api/${firstArrg}/`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

const getAllResultDetails = async (firstArrg, secondArg) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(
      `${baseURL}/tockens_api/${firstArrg}/${secondArg}/`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

const getAllResultDetailsThirdarrg = async (surveyId, collectionName , accessToken) => {
  const headers = {
    "Content-Type": "application/json",
    'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(
      `${baseURL}/tockens_api/survey/${surveyId}/${collectionName}/`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

// tockens end

const getUserAccountStatus = async (accessToken) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(`${baseURL}/userapi/getmyaccountstatus/`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

// const getSomeOtherData = async (accessToken) => {
//   // Define headers or any other configurations as needed for the second API call
//   const headers = {
//     'Content-Type': 'application/json',
//     'Authorization': `Bearer ${accessToken}`,
//   };

//   try {
//     const response = await axios.get(`${baseURL}/path/to/other/endpoint`, { headers });
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching other data:', error);
//     throw error;
//   }
// };

// You can add more functions for additional API calls if needed

//Home.js file
//Get User Details form Database

//get REF code
const getUserRefCode = async (accessToken) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(`${baseURL}/userapi/getrefcodeValue/`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

//get Banner images

const getBannerImages = async () => {
  const headers = {
    "Content-Type": "application/json",
    //'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(`${baseURL}/userapi/getbannerimages/`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

// Get values for Home banner animation properties
const getBannerAnimationValues = async () => {
  const headers = {
    "Content-Type": "application/json",
    //'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(
      `${baseURL}/userapi/banneranimationpropertys/`,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

// Get values for Home banner animation properties
const getReferralcode = async (formDataRef) => {
  const headers = {
    "Content-Type": "application/json",
    //'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.post(
      `${baseURL}/userapi/getreferralcode/`,
      formDataRef,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

//get address and No of Referrals
const getHomeDetails = async (accessToken) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(`${baseURL}/userapi/gethomedetails/`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

// Get contest all
const getContest = async (accessToken) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.post(`${baseURL}/userapi/getcontests/`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

const getPanaandAadhar = async (accessToken) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(`${baseURL}/userapi/getpanaadhar/`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

// deals visti data 
const dealsvisit = async(firstArrg , accessToken) =>{
  const headers = {
    "Content-Type": "application/json",
    'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(
      `${baseURL}/tockens_api/set_deal_visted_orders/${firstArrg}`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
}

 
//api call getting all shopping categories 

const getShoppingCategories = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(
      `${baseURL}/tockens_api/getall-products-category/`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};



//api call for shopping inner details page 
const getShoppingCategoriesInner = async (id  ) => {
  const headers = {
    "Content-Type": "application/json",
    // 'Authorization': `Bearer ${accessToken}`,
  };

  try {
    const response = await axios.get(
      `${baseURL}/tockens_api/getall-products-deals/${id}`,
      {
        method: "GET",
        headers: headers, 
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

///categories for Survey 
const getSurveyCategories = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(
      `${baseURL}/tockens_api/getallcategorys/`,
      {
        headers,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};

//surveys -list 
const getSurveyslist = async (firstArrg) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.get(`${baseURL}/tockens_api/surveyslist/${firstArrg}/`, {
      headers,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user account status:", error);
    throw error;
  }
};



export {
  api,
  getUserAccountStatus,
  getUserRefCode,
  getBannerImages,
  getBannerAnimationValues,
  getReferralcode,
  getHomeDetails,
  getContest,
  getPanaandAadhar,
  baseURL,
  getProductsAll,
  getAllResultDetails,
  getDealsAll,
  getDealsCategories,
  getDealsCategoriesInner,
  getDeal,
  getProduct,
  getAllResult,
  getAllResultNoTocken,
  getAllResultDetailsThirdarrg,
  getShoppingCategories,
  getShoppingCategoriesInner,
  getSurveyCategories,
  getSurveyslist,
  ssoURL,
  ssoRedirectUrl,
  dealsvisit,
};
