import React, { useState, useEffect } from "react";
import "./Styles/SubScriptionFeatures.css";
import { useNavigate } from "react-router-dom";
import symbol from "./images/symbol.png";
import right from "./images/right-icon.png";
import orange from "./images/orange-icon.png";
import blue from "./images/blue-icon.png";
import purple from "./images/purple-icon.png";

import { baseURL } from "../services/Aut.post.js"

const MainSubScriptionFeatures = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [subscriptionFeatures, setSubscriptionFeatures] = useState([]);
  const [subscriptionValues, setSubscriptionValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState("Clubb");
  const navigate = useNavigate();


  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken?.access;
 

  // useEffect(() => {
  //   const fetchData = async() => {
  //     try{
  //       const response = await fetch(
  //         `${baseURL}/userapi/subscription-data-login/`,
  //         {headers : headers}
  //       )
  //       if(!response.ok){
  //         throw new Error("Failed to fetch data");
  //       }
  //       const data = await response.json();
  //       console.log("dhudfuv" , data.subtype)
  //       setSelectedSubscription(data.subtype)
  //     }catch (error){
  //       setError(error.message);
  //     }
  //   };
  //   fetchData();
  // } , []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseURL}/userapi/subscription-data/`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setSubscriptionFeatures(data.subscription_features); 
        setSubscriptionValues(data.subscription_values);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleClick = (level) => {
    // console.log("Function Called");
    const SelectFeature = subscriptionFeatures.map(feature => ({
      Feature : feature.Feature,
      Value : feature[level],
    }))
    // console.log("DGDBGJDGDGJK :", JSON.stringify(SelectFeature));
    if (btoken) {
      setSelectedSubscription(level);
      // window.location.href = `/getsubscriptionvalues/${level}/Annual`;
      navigate(`/getsubscriptionvalues/${level}/Annual` , {state : {getSelectedFeature : SelectFeature}})
    } else {
      navigate("/phonesignup");
    }
  };

  const handleSymbolClick = (content) => {
    setPopupContent(content);
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setPopupContent("");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const subscriptionLevelsOrder = ["Clubb", "Citizen", "Celebrity", "Champion"];
  const subscriptionColors = {
    Clubb: "#000",
    Citizen: "#6AA6FF",
    Celebrity: "#AB65D0",
    Champion: "#E77502",
  };

  const subscriptionIcons = {
    Clubb: right,
    Citizen: blue,
    Celebrity: purple,
    Champion: orange,
  };

  // Determine the style for inactive and active subscriptions
  const getSubscriptionStyle = (level) => {
    const selectedIndex = subscriptionLevelsOrder.indexOf(selectedSubscription );
    const levelIndex = subscriptionLevelsOrder.indexOf(level);

    return {
      backgroundColor: level === selectedSubscription ? "#f0f0f0" : "#fff", // Active vs. inactive background
      color: levelIndex >= selectedIndex ? "black" : "#a0a0a0", // Inactive color is grayed out
      pointerEvents: levelIndex > selectedIndex ? "auto" : "none", // Disable click for inactive
      opacity: levelIndex >= selectedIndex ? 1 : 0.6, // Dim inactive subscriptions
      // borderRadius: level === selectedSubscription ? "10px" : "0px", // Apply border-radius 
    };
  };

  return (
    <div className="container top-space-1 ">
      {/* for mobile view  */}

   
        <div className="row top-space-2 col-12 d-md-none mb-4">
  <div className="extra-offer-wrap">
    <h1 className="text-left">Subscription Features</h1>
    {subscriptionLevelsOrder.map((level) => {
      const levelData = subscriptionValues.find(
        (v) => v.subscription_level === level
      );
      console.log("Mobile View Style for", level, getSubscriptionStyle(level)); // Debugging

      return (
        <div className="card mb-4" key={level} style={{ ...getSubscriptionStyle(level) }}>
          <div
            className="card-header text-white"
            style={{
              backgroundColor: subscriptionColors[level],
              textAlign: "center",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <h5 className="m-0">{level}</h5>
          </div>
          <div className="card-body">
            {subscriptionFeatures.map((feature) => (
              <div
                className="d-flex justify-content-between align-items-center mb-2"
                key={feature.id}
                style={{ padding: "0px 0px 10px 0px" }}
              >
                <span
                  className="feature-name"
                  style={{
                    fontWeight: "500",
                    color: "#000",
                    width: "60%",
                    paddingLeft: "10px",
                  }}
                >
                  {feature.Feature}{" "}
                  <img
                    src={symbol}
                    alt="Info"
                    onClick={() =>
                      handleSymbolClick(`${feature.Remark || "No details"}`)
                    }
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                  />
                </span>

                <span
                  className="feature-value"
                  style={{
                    fontWeight: "600",
                    paddingRight: "15px",
                    ...getSubscriptionStyle(level), // Ensuring active/inactive states
                  }}
                >
                  <img
                    src={subscriptionIcons[level]}
                    alt={level}
                    style={{ marginRight: "10px" }}
                    onClick={() =>
                      handleSymbolClick(`${feature.Remark || "No details"}`)
                    }
                  />
                  {feature[level] || "0"}
                </span>
              </div>
            ))}
            <div className="text-center mt-3 ">
              <p
                style={{
                  fontWeight: "600",
                  color: "#cccccc",
                  margin: 0,
                  textDecoration: "line-through",
                }}
              >
                ₹{levelData?.subscription_Cost || "0"} /mo
              </p>
              <p
                style={{
                  fontWeight: "600",
                  margin: 0,
                }}
              >
                ₹{levelData?.subscription_Discout || "0"} /mo
              </p>

              <button
                type="button"
                className={`btn-${level.toLowerCase()}`}
                onClick={() => handleClick(level)}
                style={{
                  backgroundColor: subscriptionColors[level] || "black",
                  color: "white",
                  borderRadius: "5px",
                  padding: "10px 15px 10px 15px",
                  fontWeight: "normal",
                  fontSize: "15px",
                }}
                disabled={level === "Clubb"}
              >
               {level === "Clubb" ? "Free" : `Get ${level}`}
              </button>
            </div>
          </div>
        </div>
      );
    })}
  </div>
</div>

      {/* for desktop view */}

      <div className="row top-space-1  col-12 d-none d-md-block">
        <div className="extra-offer-wrap">
          <h1>Subscription Features</h1>
          <div className="subscription-main-container">
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>Feature</th>
                    {subscriptionLevelsOrder.map((level) => {
                      const value = subscriptionValues.find(
                        (v) => v.subscription_level === level
                      );
                      return (
                        <th
                          key={level}
                          style={{
                            ...getSubscriptionStyle(level),
                            fontWeight: "500",
                          
                          }}
                        >
                          {level}
                          <br />
                          <p style={{color: "#ccc" ,   textDecoration: "line-through"}}>₹{value?.subscription_Cost || "0"}/mo</p>
                          ₹{value?.subscription_Discout || "0"}/mo
                        </th>
                      );
                    })}
                  </tr>
                </thead>

                <tbody>
                  {subscriptionFeatures.map((feature) => (
                    <tr key={feature.id}>
                      <td className="td-1">
                        {feature.Feature}{" "}
                        <img
                          src={symbol}
                          alt="Info"
                          onClick={() =>
                            handleSymbolClick(
                              `${feature.Remark || "No details"}`
                            )
                          }
                        />
                      </td>
                      {subscriptionLevelsOrder.map((level) => (
                        <td
                          key={`${feature.id}-${level}`}
                          style={{
                            ...getSubscriptionStyle(level),
                          }}
                        >
                          <img
                            src={subscriptionIcons[level]}
                            alt={level}
                            style={{ marginRight: "10px" }}
                            onClick={() =>
                              handleSymbolClick(
                                `${feature.Remark || "No details"}`
                              )
                            }
                          />
                          {feature[level] || "N/A"}
                        </td>
                      ))}
                    </tr>
                  ))}
                 <tr className="buttons">
  <td className="td-1"></td>
  {subscriptionLevelsOrder.map((level) => {
    const value = subscriptionValues.find(
      (v) => v.subscription_level === level
    );
    return (
      <td key={level} style={{ ...getSubscriptionStyle(level) }}>
        <button
          type="button"
          className={`btn-${level.toLowerCase()}`}
          onClick={() => handleClick(level)}
          style={{
            backgroundColor: subscriptionColors[level] || "black",
            color: "white",
          }}
          disabled={level === "Clubb"} // Disable the "Free" button
        >
          {level === "Clubb" ? "Free" : `Get ${level}`}
        </button>
      </td>
    );
  })}
</tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {popupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-popup" onClick={closePopup}>
              &times;
            </button>
            <p style={{ fontSize: "17px", padding: "25px 0px 5px 0px" }}>
              {popupContent}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainSubScriptionFeatures;
