import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom"; // Import useParams
import InnerBanner from "./InnerBanner.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Styles/ChangeSubscriptions.css";
import axios from "axios";
import { Helmet } from "react-helmet";
import { baseURL } from "../services/Aut.post.js";

function ChangeSubscriptions() {
  const [orderDetails, setOrderDetails] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);
  const { subscription_level, subscription_type } = useParams(); // Get parameters from route

  const accessTokenforReferal = JSON.parse(localStorage.getItem("user"));
  const btoken = accessTokenforReferal?.access; // Optional chaining for safety

  const location = useLocation();
  const { getSelectedFeature } = location.state || {};
  const selectedsubscriptioniFeature = getSelectedFeature;
  console.log("changeSubscriptionPROP", selectedsubscriptioniFeature)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${baseURL}/userapi/getsubscriptionvalues/${subscription_level}/Annual`;

        const response = await axios.get(url);
        setSubscriptions(response.data ? [response.data] : []);
        console.log("Subscription data:", response.data);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    };

    fetchData();
  }, [subscription_level, subscription_type]); // Added subscription_type to dependency array




  useEffect(() => {
    axios
      .post(`${baseURL}/userapi/paymentsetup/`)
      .then((response) => {
        console.log("Response:", response.data);
        setOrderDetails({
          razorpay_order_id: response.data.context.razorpay_order_id,
          razorpay_merchant_key: response.data.context.razorpay_merchant_key,
          razorpay_amount: response.data.context.razorpay_amount,
          currency: response.data.context.currency,
          callback_url: response.data.context.callback_url,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const handlePayment = async (subscription) => {
    const options = {
      key: "ByJbhV",
      txnid: `txn_${Math.random().toString(36).substr(2, 9)}`, // Unique transaction ID
      amount: subscription.subscription_Discout, // Dynamic amount 
      productinfo: "Premium Subscription",
      firstname: "Big Bonanza",
      description: "Test Payment",
      email: "john.doe@example.com",
      surl: `${baseURL}/tockens_api/payment-success/`, // Success URL
      furl:`${baseURL}/tockens_api/payment-failure/`, // Failure URL
      // order_id: orderDetails.razorpay_order_id,
    };

    // Fetch the hash from your backend
    try {
      const response = await fetch(`${baseURL}/tockens_api/generate-hash/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(options),
      });

      const { hash } = await response.json();
      console.log()
      if (!hash) {
        throw new Error("Hash generation failed. Ensure the backend is generating the hash correctly.");
      }

      // Add the hash to the transaction data
      const data = { ...options, hash };

      // PayU payment handler
      const handlers = {
        responseHandler: function (BOLT) {
          alert(453453454)
          if (BOLT.response.txnStatus === "SUCCESS") {
            console.log(subscriptions);

            console.log(subscriptions[0].subscriptionID);
            console.log("Payment successful", BOLT.response);
            // Redirect to payment success page

            console.log("Payment successful", BOLT.response);

            // Make API call for payment success
            fetch(`${baseURL}/tockens_api/payment-success/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${String(btoken)}`,
              },
              body: JSON.stringify({
                Payuid: BOLT.response.mihpayid,
                status: BOLT.response.status,
                paymentType: "subscription",
                hash: BOLT.response.hash,
                key: BOLT.response.key,
                txnid: BOLT.response.txnid,
                amount: BOLT.response.amount,
                productinfo: BOLT.response.productinfo,
                address: BOLT.response.address,
                phone: BOLT.response.phone,
                firstname: BOLT.response.firstname,
                email: BOLT.response.email,
                add_date_time: new Date().toISOString(),
                subscriptionID: subscriptions[0].subscriptionID,
                subscription_Bonus_Tockens: subscriptions[0].subscription_Bonus_Tockens,
                subscription_level: subscriptions[0].subscription_level,
              })
            })
              .then(response => response.json())
              .then(data => {
                console.log("Payment success API response:", data);
                // Redirect to payment success page

                window.location.href = "/payment-success";
              })
              .catch(error => {
                console.error("Error in success API call:", error);
              });

            //window.location.href = "/payment-success";
          } else if (BOLT.response.txnStatus === "FAILED") {
            console.log("Payment failed", BOLT.response);
            // Make API call for payment failure
            fetch(`${baseURL}/tockens_api/payment-failure/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${String(btoken)}`,
              },
              body: JSON.stringify({
                txnid: BOLT.response.txnId,
                paymentType: "subscription",
                amount: BOLT.response.amount,
                product_id: BOLT.response.productInfo,
                address: BOLT.response.address,
                phone: BOLT.response.phoneNumber,
                firstname: BOLT.response.firstName,
                email: BOLT.response.email,
                add_date_time: new Date().toISOString()
              })
            })
              .then(response => response.json())
              .then(data => {
                console.log("Payment failure API response:", data);
                // Redirect to payment failed page
                window.location.href = "/payment-failed";
              })
              .catch(error => {
                console.error("Error in failure API call:", error);
              });
            // Redirect to payment failed page
            //window.location.href = "/payment-failed";
          }
        },
        catchException: function (BOLT) {
          console.error("Exception occurred", BOLT);
        },
      };

      // Launch PayU BOLT SDK
      if (window.bolt) {
        window.bolt.launch(data, handlers);
      } else {
        alert("BOLT SDK is not loaded.");
      }
    } catch (error) {
      console.error("Error generating hash:", error);
    }

  }


  return (
    <div>
      <InnerBanner />
      <Helmet>
        {/* <script src="https://jssdk-uat.payu.in/bolt/bolt.min.js"></script> */}
        <script id="bolt" src="https://jssdk.payu.in/bolt/bolt.min.js" bolt-color="af636b" bolt-logo="http://boltiswatching.com/wp-content/uploads/2015/09/Bolt-Logo-e14421724859591.png"></script>
      </Helmet>
      <div className="Subscriptions-bg-1">
        <div className="wrapper">
          <h1>Subscriptions - {subscription_level}</h1>
          <div className="ChangeSubscriptions-wrap">
            <div className="ChangeSubscriptions-box-1">
              <h1 style={{ fontSize: "26px" }}>YEARLY SUBSCRIPTION</h1>
              <hr />

              {subscriptions.length > 0 ? (
                subscriptions.map((subscription) => (
                  <div key={subscription.id}>
                    <h3>
                      <b>
                        <span style={{ color: "#FA4E01", fontWeight: "700", fontSize: "40px" }}>
                          ₹{subscription.subscription_Discout}
                        </span>{" "}{" "}
                        <span
                          style={{
                            fontSize: "23px",
                            textDecoration: "line-through",
                            display: "inline-block",
                            color: "#ccc",
                          }}
                        >
                          ₹{subscription.subscription_Cost}
                        </span>
                      </b>
                    </h3>

                    <button
                      className="btn-2"
                      onClick={() => handlePayment(subscription)}
                      style={{ backgroundColor: "#FA4F01", width: "100%", borderRadius: "20px", border: "none", margin: "10px 0px 10px 0px" }}
                    >
                      Proceed
                    </button>
                  </div>
                ))
              ) : (
                <div>No subscriptions available.</div>
              )}

              {selectedsubscriptioniFeature && (
                <div className="selected-features">
                  <ul>
                    {selectedsubscriptioniFeature.map((feature, index) => (
                      <li key={index} style={{ padding: "0px 0px 10px 0px", fontWeight: "500", fontSize: "18px" }}>
                        {feature.Feature}: {feature.Value}
                      </li>
                    ))}
                  </ul>
                </div>
              )}




              {subscriptions.length > 0 ? (
                subscriptions.map((subscription) => (
                  <div>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: subscription.subscription_About,
                      }}
                    />
                    <a href="/TermsandConditions" style={{ color: "#FA4E01" }}>Terms & Conditions</a>
                  </div>
                ))
              ) : (
                <div>No subscriptions available.</ div>
              )}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeSubscriptions;
