import React, { useEffect, useState } from "react";
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { baseURL, getShoppingCategoriesInner } from "../services/Aut.post.js";

function ShoppingCategoryInner() {
  const [allProducts, setAllProducts] = useState([]);
  const navigate = useNavigate();
  // Retrieve access token from local storage
  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken?.access;

  

  // Get category ID and category name from route params
  const { id, cname } = useParams();

  // Fetch data when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Pass the category ID and access token to the service
        const data = await getShoppingCategoriesInner(id, btoken); 
        setAllProducts(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching deals:", error);
      }
    };

    fetchData();
  }, [id, btoken]);



    // Function to handle the item click and navigate with state
    const onBuyProducts = (value) => {
      navigate(`/Product-details/${value.product_unqcode}`, {
        state: { uniqueId: value.unq_id }, // Pass the uniqueId via state
      });
    };

  const ShoppingCategoryName =   localStorage.getItem("selectedShoppingCategoryName");


  return (
    <div className="top-space-1">
      <div className="extra-offer-wrap">
        <h1>{ShoppingCategoryName}</h1>
        <div className="extra-offer-flex-2-wrap">
          {allProducts.length > 0 ? (
            allProducts.map((product, index) => (

              <div className="extra-offer-2" key={index}>
                <div className="extra-offer-2-img">
                  <img
                    src={
                      product.product_image
                        ? `${baseURL}/${product.product_image}`
                        : amazon
                    }
                    alt={product.product_title || "Product"}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = amazon;
                    }}
                  />
                </div>
                {/* Explore Details */}
                <h5>{product.product_title || "Product Name"}</h5>
                <button onClick={() => onBuyProducts(product)}>
                Explore Details
                </button>
              </div>
              
            ))
          ) : (
            <div>Loading products...</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ShoppingCategoryInner;
