import React, { useEffect, useState } from "react";
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { baseURL, getDealsCategories } from "../services/Aut.post.js";

function DealsCategory({isAuthenticated}) {
  const [allDeals, setAllDeals] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => { 
      try {
        const data = await getDealsCategories();
        setAllDeals(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching deals:", error);
      }
    };

    fetchData();
  }, []);

  // const onClickCategories = (value) => {
  //   localStorage.setItem("selectedCategoryName", value.category_name);
    
  //   if (isAuthenticated) {
  //     navigate(`/category/${value.id}`);
  //   } else {
  //     // Show the popup message
  //     const popup = document.querySelector(".custom-popup-container");
  //     popup.style.display = "flex";
  //   }
   
  // };

  const onClickCategories = (value) => {
      localStorage.setItem("selectedCategoryName", value.category_name);
      
      navigate(`/deals/category/${value.category_name}/${value.id}`);
     
    };



  return (
    <>
      <div className="top-space-1">
        <div className="extra-offer-wrap">
          <h1>Deals Categories</h1> {/* Page Title */}
          <div className="extra-offer-flex-2-wrap">
            {allDeals.length > 0 ? (
              allDeals.map((value, index) => (

                <div className="extra-offer-2" key={index}>
                   <div className="extra-offer-2-img" key={index}>
                   <img
                    src={
                      value.category_image
                        ? `${baseURL}${value.category_image}`
                        : amazon
                    }
                    alt={value.category_image || "product"}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = amazon;
                    }}
                  />
                   </div>
                  <h6>
                 
                    {/* Title (optional, if available) */}
                    
                    
                    {/* Navigate to the inner page of the category using the category ID */}
                    {/* <Link to={`/category/${value.id}`}>
                      View {value.category_name} Deals
                    </Link> */}
                     <button onClick={() => onClickCategories(value)}>
                     View {value.category_name} Deals
                  </button>
                  </h6>
                </div>  
              ))
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </div>
      </div>

    
    </>
  );
}

export default DealsCategory;
