import React from "react";
import { useNavigate } from "react-router-dom";

const Failure = () => {
  const navigate = useNavigate();

  return (

    // <div style={{ textAlign: "center", marginTop: "50px" }}>
    //   <h1>Payment Failed ❌</h1>
    //   <p>Something went wrong. Please try again.</p>
    //   <button onClick={() => navigate("/")}>Try Again</button>
    // </div>

    <div className="top-space-1">
        <div className="claim-page ">
          <div className="claim-page-bg payment-status-container failure">
          <div className="">
      <h1>Payment Failed ❌</h1>
      <p>
        We encountered an issue while processing your payment. Please try again 
        or contact support if the problem persists.
      </p>
      {/* <div className="button-group">
      <button  className="btn-retry  w-80" onClick={() => navigate("/")}>Try Again</button>
       
      </div> */}
    </div>
    <button className="button-orange-1 w-80" onClick={() => navigate("/")}>     Back to Home</button>
          </div>
        </div>
      </div>

);
};

export default Failure;
