import React from "react";
import { useNavigate } from "react-router-dom";
import "./Styles/PaymentStatus.css"; // Include custom CSS styles

const SuccessfulPayment = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/"); // Redirect to home page
  };

  return (
    <div className="top-space-1">
      <div className="claim-page">
        <div className="claim-page-bg payment-status-container success">
          <h1>Payment Successful 🎉</h1> 
          <p>
            Thank you for your payment! Your transaction has been successfully
            processed. You can now enjoy your subscription benefits.
          </p>
          <button className="button-orange-1 w-80" onClick={handleGoHome}>
            Back to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessfulPayment;
