import React, { useEffect, useState } from "react";
import "./Styles/ExtraOffers.css";
import amazon from "./images/amazon-img-1.png";
import { Link, useParams , useNavigate } from "react-router-dom";
import { baseURL, getDeal , dealsvisit } from "../services/Aut.post.js";

function ClaimPage() {
  const accessTokenforReferal = JSON.parse(localStorage.getItem("user"));
  const btoken = accessTokenforReferal?.access;

  const [openUrl, setOpenUrl] = useState("");
  const { unq_id } = useParams();
  const [getDealOne, setGetDealOne] = useState(null); // Initially null to check loading
  const [loading, setLoading] = useState(true); // Loading state
  const [getdealsvisit , setdealsvisit] = useState(null);

  const navigate = useNavigate();

  // useEffect(() =>{
  //   const dealsvisitfeactcheddata = async() =>{
  //     try{
  //       const data = await dealsvisit(unq_id,btoken);
  //       // console.log("DATATATA" , data)
  //       setdealsvisit(data);
  //       setLoading(false)
  //     }
  //     catch (error) {
  //       console.error("Error fetching deal:", error);
  //       setLoading(false); // Even on error, stop loading
  //     }
  //   }
  //   dealsvisitfeactcheddata();
  // }, [unq_id , btoken] );

  // Function to handle button click
//   const handleClick = () => {
//     if (openUrl) {
//       // Open the affiliate link in a new tab
//       window.open(openUrl, "_blank");
//     } else {
//       console.error("Affiliate link not found.");
//     }
//     // Redirect the user to the order ID page after opening the affiliate link
//     // window.location.href = `/order-id/${unq_id}` ;
//      console.log("FKFDGDBGLGIFGIBL" ,getdealsvisit )
//     localStorage.setItem("visitedtockens", JSON.stringify(getdealsvisit));
//     navigate(`/order-id/${unq_id}` , {state : {getdealsvisit}})
   
// };

// const messsageUpdate = getdealsvisit.message === "Visited tokens order created successfully." ? true : false



const handleClick = async () => {
  setLoading(true);

  try {
    // Fetch API data on button click
    const data = await dealsvisit(unq_id, btoken);
    setdealsvisit(data);
    console.log("Fetched Data:", data);



    // Store data in localStorage
    localStorage.setItem("visitedtockens", JSON.stringify(data));


    // Open the affiliate link if available
    if (openUrl) {
      window.open(openUrl, "_blank");
    } else {
      console.error("Affiliate link not found.");
    }

    // Redirect the user to the order ID page with fetched data
    navigate(`/order-id/${unq_id}`, { state: { getdealsvisit: data } });
  } catch (error) {
    console.error("Error fetching deal:", error);
  } finally {
    setLoading(false);
  }
};



  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch deal data and set the affiliate link
        const data = await getDeal(unq_id, btoken);
        setGetDealOne(data);
        setOpenUrl(data.affiliate_link); // Set the affiliate link to state
        setLoading(false); // Data loaded
      } catch (error) {
        console.error("Error fetching deal:", error);
        setLoading(false); // Even on error, stop loading
      }
    };

    fetchData();
  }, [unq_id, btoken]);

  return (
    <>
      <div className="top-space-1">
        <div className="claim-page">
          <div className="claim-page-bg">
            <div className="claim-page-header">
              {loading ? (
                // Display a placeholder image while loading
                <img src={amazon} alt="Loading..." />
              ) : (
                <img
                  src={
                    getDealOne?.marketplace_image
                      ? `${baseURL}${getDealOne.marketplace_image}`
                      : amazon
                  }
                  alt={getDealOne?.unq_id || "product"}
                />
              )}
            </div>

            {getDealOne && (
              <>
                <h3>{getDealOne.tockens_benefit}</h3>

                <button className="button-orange-1 w-80" onClick={handleClick}>
                  {/* Earn upto {getDealOne.visited_tockens} Tokens */}
                  Unlock the deal
                </button>
              </>
            )}
          </div>

          <div className="backhome">
            <Link to="/">Back to Home</Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClaimPage;
