import React, { useState, useEffect } from 'react';
import "./Styles/ExtraOffers.css";

function DealsError() {
  // const location = useLocation();
  // const { getvisitdeals = {}, getDealOne = {} } = location.state || {};
  // const dealsvisit = getvisitdeals;
  // const active_deal_after = getDealOne?.deals_active_type;

  const active_deal_after = localStorage.getItem("times")
  // const [timeLeft, setTimeLeft] = useState(active_deal_after > 0 ? active_deal_after * 60 * 60 * 1000 : 0); // Convert hours to milliseconds

  // useEffect(() => {
  //   if (timeLeft > 0) {
  //     const timer = setInterval(() => {
  //       setTimeLeft((prevTime) => {
  //         if (prevTime <= 1000) {
  //           clearInterval(timer); // Stop the timer
  //           return 0;
  //         }
  //         return prevTime - 1000; // Decrease by 1 second
  //       });
  //     }, 1000);

  //     return () => clearInterval(timer); // Cleanup on component unmount
  //   }
  // }, [timeLeft]);

  // const formatTime = (milliseconds) => {
  //   const totalSeconds = Math.floor(milliseconds / 1000);
  //   const hours = Math.floor(totalSeconds / 3600);
  //   const minutes = Math.floor((totalSeconds % 3600) / 60);
  //   const seconds = totalSeconds % 60;

  //   return `${hours}h ${minutes}m ${seconds}s`;
  // };

  const handleClick = () => {
    const redirectUrl = `/`;
    window.location.href = redirectUrl;
  };

  const dealsErrorMsg = () => {
    if (active_deal_after === "0") {
      return "This is a one-time lifetime deal.";
    } else if (active_deal_after > 0) {
      return (
        <>
          {/* You have already used this deal. Please wait{" "}
          <span style={{ color: "red",  }}>
            {formatTime(timeLeft)}
          </span>{" "}
          before you can use it again. */}
         <h3>Thank you for shopping with us!</h3>
        <p>Your order will be reviewed shortly.</p>
        <p>If you have any questions, please contact our support team.</p> 
        </>
      );
    } else {
      return "The deal is now available!";
    }
  };

  return (
    <>
      <div className="top-space-1">
        <div className="claim-page">
          <div className="claim-page-bg">
            {/* <img src={successlogo} alt="logo" /> */}
            <h3></h3>
            {/* <p>Visited Tockens: <span>{dealsvisit.visited_tockens}</span></p> */}
            {/* <p>{dealsvisit.message}</p> */}
            <p>{dealsErrorMsg()}</p>
            <button className="button-orange-1 w-80" onClick={handleClick}>
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DealsError;
