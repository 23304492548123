import React from "react";
import { Helmet } from "react-helmet";

const PayUIntegration = () => {
  const handlePayment = async () => {
    const transactionData = {
      key: "PplXWj", // Replace with your key
      txnid: `txn_${new Date().getTime()}`, // Unique transaction ID
      amount: "10", // Replace with the transaction amount
      productinfo: "Test Product",
      firstname: "John",
      email: "john@example.com",
      phone: "9876543210",
      surl: "http://localhost:3000/success", // Replace with your success URL
      furl: "http://localhost:3000/failure", // Replace with your failure URL
    };

    // Fetch the hash from your backend
    try {
      const response = await fetch("http://localhost:5000/generate-hash", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(transactionData),
      });
      const { hash } = await response.json();

      const data = { ...transactionData, hash };

      const handlers = {
        responseHandler: function (BOLT) {
          if (BOLT.response.txnStatus === "SUCCESS") {
            console.log("Payment successful", BOLT.response);
          } else if (BOLT.response.txnStatus === "FAILED") {
            console.log("Payment failed", BOLT.response);
          }
        },
        catchException: function (BOLT) {
          console.error("Exception occurred", BOLT);
        },
      };

      if (window.bolt) {
        window.bolt.launch(data, handlers);
      } else {
        alert("BOLT SDK is not loaded.");
      }
    } catch (error) {
      console.error("Error generating hash:", error);
    }
  };

  return (
    <div>
        123
      <Helmet>
        <script src="https://jssdk-uat.payu.in/bolt/bolt.min.js"></script>
      </Helmet>
      <h1>PayU Integration</h1>
      <button onClick={handlePayment}>Pay Now</button>
    </div>
  );
};

export default PayUIntegration;
