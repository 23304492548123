
import React from 'react';
import "./Styles/ExtraOffers.css";
import successlogo from "./images/sucess-msg.png"
import { useLocation } from 'react-router-dom';
import { useState , useEffect} from 'react';

function ConfirmPage() {

  const location = useLocation();
  const [state] = useState(location.state || {})

  console.log("STAATATT" , state)
  const {getvisitdeals} = location.state || {} ;
  const NoofdealsVisited = getvisitdeals  

  console.log(NoofdealsVisited)
  // alert(NoofdealsVisited)

  const handleClick = () => {
    const redirectUrl = `/`;
    window.location.href = redirectUrl;
  };




  return (
    <>
      <div className="top-space-1">
        <div className="claim-page">
      
          <div className="claim-page-bg">
          <img src={successlogo} alt="logo" />
          {/* <h4>Visited Tockes: <span> {NoofdealsVisited.visited_tockens}</span></h4> */}
          <h3>Your details are noted successfully</h3>
 
          <p>After reviewing your task and it's successful completion</p>
          <p>task tockens will be added to your account  in next 15 days</p>
          {/* <p>We are processing your order and will update you shortly.</p>
          <p>If you have any questions, feel free to contact our support team.</p> */}
            <h3>
       
            </h3>  
            <button class="button-orange-1 w-80" onClick={handleClick}>Back to Home</button>
          </div>     
        </div>
      </div>
    </>
  );
}

export default ConfirmPage;
