import React from "react";
import { useNavigate } from "react-router-dom";
import "./Styles/PaymentStatus.css"; // Include custom CSS styles

const FailedPayment = () => {
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate("/retry-payment"); // Redirect to retry payment page
  };

  const handleGoHome = () => {
    navigate("/"); // Redirect to home page
  };

  return (
   
    <div className="top-space-1">
        <div className="claim-page ">
          <div className="claim-page-bg payment-status-container failure">
          <div className="">
      <h1>Payment Failed ❌</h1>
      <p>
        We encountered an issue while processing your payment. Please try again 
        or contact support if the problem persists.
      </p>
      {/* <div className="button-group">
        <button onClick={handleRetry} className="btn-retry">
          Retry Payment
        </button>
       
      </div> */}
    </div>
            <button className="button-orange-1 w-80" onClick={handleGoHome}>
              Back to Home
            </button>
          </div>
        </div>
      </div>
  );
};

export default FailedPayment;
